import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { MdClose } from 'react-icons/md'
import { labels } from '../const'
import ty from 'utils/typographie'
import useCssCloseAnimation from 'hooks/useCssCloseAnimation'

type Props = {
  onClose: () => void
  featuredColumn: string
  onSelect: (opt: keyof typeof labels) => void
}

const options: (keyof typeof labels)[] = [
  'numPlusProducts',
  'num46PlusProducts',
  'numProducts',
  'plusSizeModels',
  'minSize',
  'priceMin',
  'delivery',
  'isGerman',
]

export default function MobileModal(props: Props) {
  const [isClosing, onClose] = useCssCloseAnimation(300, props.onClose)

  const handleSelect = (opt) => () => {
    props.onSelect(opt)
    onClose()
  }

  return (
    <Wrapper className="Modal" isClosing={isClosing}>
      <div className="close-wrapper" onClick={onClose}>
        <button>
          Filter schließen <MdClose />
        </button>
      </div>

      {options.map((opt) => (
        <Row key={opt} onClick={handleSelect(opt)} selected={props.featuredColumn === opt}>
          {labels[opt]}
        </Row>
      ))}
    </Wrapper>
  )
}

const show = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const hide = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  z-index: 999999999999999999999999999999999;
  animation: 300ms ${show} ease-out;
  ${(p) =>
    p.isClosing &&
    css`
      animation: 300ms ${hide} ease-out;
    `}

  overflow: auto;
  padding-bottom: 50px;

  > .close-wrapper {
    background: #eeeced;
    > button {
      padding: 15px;
      flex: 1;
      display: block;
      width: 100%;
      border: none;
      background: none;
      text-align: right;
      color: #323232;
      font-family: Raleway;
      font-size: 11px;
      letter-spacing: 2.4px;
      line-height: 20px;
      cursor: pointer;
      text-transform: uppercase;

      > svg {
        background: white;
        border-radius: 20px;
        margin-left: 8px;
        padding: 3px;
        font-size: 20px;
        margin-bottom: -6px;
      }
    }
  }
`

const Row = styled.div`
  ${ty([16, 0, 22], 'Open Sans')}
  padding: 20px 0 10px 0;
  margin: 0 13px;
  border-bottom: 1px solid #eeeced;
  cursor: pointer;
  position: relative;

  ${(p) =>
    p.selected &&
    css`
      &:before {
        content: '';
        background: lightgrey;
        position: absolute;
        left: -13px;
        right: -13px;
        top: 0;
        bottom: 0;
        z-index: -1;
      }
    `}
`
