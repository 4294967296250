/* eslint-disable react/no-unescaped-entities */
import * as React from 'react'
import styled from 'styled-components'
import Table from './Table'
import Container from 'theme/atoms/Container'
import Link from 'theme/atoms/Link'
import ArrowSvg from 'svg/Arrow'
import ty from 'utils/typographie'
import Grid from './Grid/Grid'
import Formula from './images/formula.png'
import Meta from 'theme/atoms/Meta'
import Layout from 'containers/Layout'

type Props = {
  location: {
    pathname: string
  }
}

export default function BrandIndex(props: Props) {
  const [showFullTextOne, setShowFullTextOne] = React.useState(false)
  const [showFullTextTwo, setShowFullTextTwo] = React.useState(false)

  return (
    <Layout>
      <Container>
        <Meta
          type="website"
          pathname={props.location.pathname}
          title={'Plus Size Brandindex 2021 | Wundercurves'}
          description={
            'Der Plus Size Brandindex von Wundercurves. Welche Marken sind die besten für Plus Size Shopping: Die Top 50 für 2021.'
          }
        />
        <Wrapper>
          <Grid />
          {!showFullTextOne ? (
            <div className="content-block">
              <h3>Plus-Size Brandindex 2021</h3>
              <p>2020 war wohl das Jahr des Online-Shoppings, auch für Kleidung.</p>
              <p>
                In einem wachsenden Markt gibt es jedoch auch Menschen, deren Bedürfnisse in vielen
                Shops nicht berücksichtigt werden. Laut einer Studie tragen mehr als die Hälfte
                aller Frauen in Deutschland Konfektionsgröße 42 und größer.
              </p>
              <p>
                Ein Fakt, der sich jedoch in den Kleidergrößen vieler Shops kaum widerspiegelt. Wir
                von Wundercurves haben dies zum Anlass genommen, den Plus Size Brandindex 2021 ins
                Leben zu rufen.
              </p>
              <Link onClick={() => setShowFullTextOne(!showFullTextOne)}>
                mehr lesen <ArrowSvg />
              </Link>
            </div>
          ) : (
            <div className="content-block">
              <h3>Plus-Size Brandindex 2021</h3>
              <p>2020 war wohl das Jahr des Online-Shoppings, auch für Kleidung.</p>
              <p>
                In einem wachsenden Markt gibt es jedoch auch Menschen, deren Bedürfnisse in vielen
                Shops nicht berücksichtigt werden. Laut einer Studie tragen mehr als die Hälfte
                aller Frauen in Deutschland Konfektionsgröße 42 und größer.
              </p>
              <p>
                Ein Fakt, der sich jedoch in den Kleidergrößen vieler Shops kaum widerspiegelt. Wir
                von Wundercurves haben dies zum Anlass genommen, den Plus Size Brandindex 2021 ins
                Leben zu rufen.
              </p>
              <h4>Untersuchung: Welcher Online-Shop bietet das beste Plus Size Einkaufserlebnis</h4>
              <p>
                Ziel des Plus Size Brandindex ist es, den besten Online-Shop für Frauen mit
                Konfektionsgröße 42+ zu ermitteln. Dafür untersuchten wir 50 Modeshops auf Faktoren
                wie das Angebot an großen Größen, den Anteil von Plus Size Kleidung an der gesamten
                Auswahl, die Darstellung der Kleidung an kurvigen Models, aber auch wichtige
                Online-Shopping-Faktoren wie Preise und Versandkosten.
              </p>
              <p>
                Auf Basis dieser Werte haben wir ein Punktesystem entwickelt, um die Shops
                miteinander vergleichen zu können. Auch wenn das Shoppingerlebnis nicht überall
                gleich ausfällt, sind trotzdem alle Stores aus unserem Ranking Highlights in der
                Modelandschaft - denn sie haben sich ihren Platz erkämpft: Der Plus Size Brand Index
                rankt die Top 50 der Online-Shops mit der größten Auswahl an Plus-Size-Mode.
              </p>
              <p>
                Als Marktplatz für Plus Size Kleidung setzen wir mit Wundercurves den Fokus auf
                große Größen, authentische Inspirationen und ein schnelles und unkompliziertes
                Einkaufserlebnis. Aufgrund der gebotenen Neutralität haben wir uns bewusst aus der
                Betrachtung der Online-Shops für Plus Size Mode herausgenommen.
              </p>
              <h4>Plus Size – ein Tabu Thema?</h4>
              <p>
                Laut dem HOHENSTEIN Institut beträgt{' '}
                <Link to="https://docplayer.org/48995994-Fakten-und-hintergruende-zu-den-konfektionsgroessen-in-deutschland.html">
                  die Kleidergröße der deutschen Durchschnittsfrau 42, Tendenz 44
                </Link>
                . Trotzdem erleben viele kurvige Frauen Frust und Enttäuschung beim Online-Shopping,
                gerade im Vergleich zum Normalgrößensegment. Oft geht die Auswahl nicht in die
                wirklich hohen Konfektionsgrößen hinein, ist generell zu gering, um den eigenen
                Vorstellungen gerecht zu werden, oder aber wird nicht authentisch präsentiert. Die
                Darstellung von Kleidung an schlanken Körpern in kleineren Konfektionsgrößen ist
                zwar nicht verwerflich, aber bedeutet, dass ein Großteil der shoppenden Frauen nicht
                einschätzen kann, wie das Kleidungsstück an ihnen ungefähr aussehen wird.
              </p>
              <p>
                Um Aufmerksamkeit für dieses Problem zu schaffen und Plus Size Frauen zu zeigen, wer
                die Bedürfnisse kurviger Frauen wirklich erkennt, haben wir den Plus Size Brandindex
                2021 ins Leben gerufen.
              </p>
              <Link onClick={() => setShowFullTextOne(!showFullTextOne)}>
                <span className="inverted">
                  <ArrowSvg />
                </span>{' '}
                weniger lesen
              </Link>
            </div>
          )}
          <span id="Tabelle"></span>
          <Table />
          <span id="Methodik"></span>
          {!showFullTextTwo ? (
            <div className="content-block">
              <h3>Plus Size Brandindex Methodik</h3>
              <p>
                Der Plus Size Brandindex 2021 bewertet den besten Online-Shop für Menschen mit
                Übergröße. Dieser Index setzt sich aus 8 Faktoren zusammen und untersucht 50 Brands,
                welche entweder Plus Size Spezialisten oder Plus Size Vertreter sind.
              </p>
              <Link onClick={() => setShowFullTextTwo(!showFullTextTwo)}>
                mehr lesen <ArrowSvg />
              </Link>
            </div>
          ) : (
            <div className="content-block">
              <h3>Plus Size Brandindex Methodik</h3>
              <p>
                Der Plus Size Brandindex 2021 bewertet den besten Online-Shop für Menschen mit
                Übergröße. Dieser Index setzt sich aus 8 Faktoren zusammen und untersucht 50 Brands
                welche entwederPlus Size Spezialisten oder Plus Size Vertreter sind.
              </p>
              <h4>Einleitung</h4>
              <p>
                Wundercurves präsentiert den Plus Size Brandindex 2021 - der Guide für ein optimales
                Shopping-Erlebnis für kurvige Frauen. Laut einer Studie tragen{' '}
                <Link to="https://docplayer.org/48995994-Fakten-und-hintergruende-zu-den-konfektionsgroessen-in-deutschland.html">
                  mehr als die Hälfte aller Frauen in Deutschland Konfektionsgröße 42 und größer
                </Link>
                , dennoch werden ihre Bedürfnisse beim Shopping kaum berücksichtigt. Deswegen
                untersuchen wir anhand ausgewählter Faktoren 50 große Online-Shops hinsichtlich
                ihres Potenzials zum perfekten Einkaufserlebnis für Frauen mit hohen
                Konfektionsgrößen.
              </p>
              <h4>Zielsetzung</h4>
              <p>
                Unser Ziel ist es, Online-Shops mit großer Auswahl an Plus Size Mode sowie
                ausgewählte Vertreter aus dem Bereich Plus Size Shopping miteinander zu vergleichen
                und anhand einer Rangliste zu bewerten, wie ernst sie die Bedürfnisse von Frauen mit
                einer Konfektionsgröße 42+ nehmen.
              </p>
              <h4>Auswahl der Kriterien</h4>
              <p>
                Als Experten für Plus Size Shopping nutzten wir unter anderem zahlreiches Feedback
                unserer Kunden, um die wichtigsten Faktoren und Kriterien für ein erfolgreiches
                Shopping-Erlebnis zu ermitteln.
              </p>
              <p>
                Dazu zählen naturgemäß größenunabhängige Faktoren wie die Preisgestaltung,
                Versandbedingungen und die Gesamtauswahl an Produkten. Spezifisch für Frauen mit
                hohen Konfektionsgrößen ist es wichtig, dass ihre gewünschten Größen überhaupt
                verfügbar sind. Dafür haben wir untersucht, wie viele Plus Size Produkte in welchem
                Umfang im Angebot sind, welchen Anteil diese vom Gesamtangebot einnehmen und wie
                viele Produkte speziell ab Größe 46 verfügbar sind. Denn wenn die große Auswahl nur
                mit Produkten bis Größe 46 zustande kommt, dann kann mitnichten davon gesprochen
                werden, dass die Bedürfnisse kurviger Frauen ernst genommen werden. Unabhängig von
                der Konfektionsgröße sollte jede kurvige Frau sich schön fühlen dürfen, was nur mit
                einer großen Auswahl auch oberhalb der Konfektionsgröße 46 gewährleistet werden
                kann.
              </p>
              <p>
                Um einen authentischen Eindruck der Mode zu erhalten, ist es ebenfalls wichtig, dass
                die Kleidung von Plus Size Models präsentiert wird. Deshalb haben wir auch diesen
                Punkt in die Bewertung einfließen lassen. Als ergänzenden Faktor mit geringerer
                Gewichtung fließt noch in die Gesamtbewertung mit ein, ob der Shop für den deutschen
                Markt optimiert und somit deutschsprachig ist, denn auch das kann für bestimmte
                Zielgruppen ein optimales Shopping-Erlebnis erst ermöglichen.
              </p>
              <h4>Gewichtung</h4>
              <p>
                Die Bewertung der Shops setzt sich aus 8 verschiedenen Faktoren zusammen. Jedem
                Faktor haben wir je nach Auswirkung der Faktoren auf das Einkaufserlebnis eine
                andere Gewichtung zugewiesen, um eine möglichst genaue Endbewertung und Rangfolge zu
                erhalten.
              </p>
              <p>Die Gewichtung wird wie folgt vergeben: </p>
              <ul>
                <li>Deutsche Homepage – 10 % der Gesamtpunktzahl.</li>
                <li>Anzahl Produkte Plus Size – 10 % der Gesamtpunktzahl.</li>
                <li> Anzahl Produkte ab Größe 46 – 40 % der Gesamtpunktzahl. </li>
                <li>Anzahl Produkte insgesamt – 1% der Gesamtpunktzahl. </li>
                <li>Verfügbare Größen – 23 % der Gesamtpunktzahl.</li>
                <li> Plus Size Model Bilder – 15 % der Gesamtpunktzahl. </li>
                <li>Versandkosten – 1 % der Gesamtpunktzahl.</li>
                <li>Kleidungspreise – wird für die Endnote nicht berücksichtigt.</li>
              </ul>
              <h4>Zeitraum</h4>
              <p>
                Für die Informationen in der Tabelle besuchten wir in dem Zeitraum vom 12.-30.
                Oktober 2020 die jeweiligen Webseiten der Shops.
              </p>
              <h4>Plus Size Spezialist / Vertreter</h4>
              <p>
                In der Tabelle werden Marken gelistet, die eine Mindestanzahl von Produkten in
                großen Größen anbieten. Aus einer bestehenden ersten Sammlung wurden die Marken
                ausgewählt, die mindestens 300 Produkte ab Größe 46 verkaufen und nach Deutschland
                liefern. Ausgeschlossen waren nicht vertrauenswürdige Marken, bspw. ohne gesicherten
                Webseitenzugang.
              </p>
              <p>
                Wir haben nur Marken betrachtet, die auch ihren eigenen Online-Shop anbieten. Dabei
                handelt es sich um Modemarken, die ihren eigenen Shop anbieten und entweder
                ausschließlich Plus Size Mode vertreiben oder eine Vielzahl Produkte auch in großen
                Größen anbieten. Außerdem befinden sich in der Liste Plus Size Shops, also Shops,
                die ausschließlich Plus Size Mode vertreiben, aber ihr Sortiment auch aus
                unterschiedlichen Modemarken zusammenstellen.
              </p>
              <p>
                Die Aufteilung in Plus Size Vertreter und Plus Size Spezialisten orientiert sich
                direkt am Angebot: Shops und Marken, die ausschließlich Plus Size Mode anbieten,
                wurden als Spezialisten bezeichnet; Marken, die alle Größen abdecken wurden als Plus
                Size Vertreter bezeichnet.
              </p>
              <h4>Wie viele Produkte insgesamt</h4>
              <p>
                Die Werte haben wir wie folgt ermittelt: Für die Zahlen zur Menge der Produkte
                konnten wir in den meisten Online-Shops die Funktion zum Anzeigen aller Produkte in
                der Kategorie „Bekleidung“, „Mode“, „Damenmode“, o.ä. verwenden. Solange der Shop
                also eine Oberkategorie hat, die alle verfügbaren Bekleidungsprodukte für Damen
                anzeigt, konnten wir diese Anzahl direkt ablesen. Dabei achteten wir darauf, dass
                Produkte, die nicht in dem Sinne Plus Size relevant sind, wie etwa Schuhe und
                Accessoires, nicht mitgezählt werden.
              </p>
              <p>
                Für die Anzahl der verfügbaren Plus Size Produkte haben wir entsprechend gefiltert:
                Für alle Plus Size Produkte haben wir alle verfügbaren Größen ab der
                Konfektionsgröße 42 bzw. 46 ausgewählt und die entsprechenden Anzahlen verwendet.
                Dabei haben wir zum Beispiel auch Zwischengrößen und Lang- bzw. Kurzgrößen
                berücksichtigt, falls diese einer Konfektionsgröße 42 bzw. 46 oder höher
                entsprachen.
              </p>
              <p>
                Bei Shopsystemen, die keine allgemeine Oberkategorie für Bekleidung anbieten, haben
                wir die Produktanzahlen aller Unterkategorien zu Bekleidung zusammengezählt. In den
                Fällen, wo dies einfacher erschien, haben wir die Gesamtzahl aller Produkte (die in
                manchen Shops zum Beispiel auch Accessoires, Schuhe etc. enthielt) genommen und
                davon die Produktanzahlen der Nicht-Bekleidungskategorien abgezogen.
              </p>
              <p>
                Für Shops, die ihre Größen nicht in deutschen Konfektionsgrößen angeben, haben wir
                anhand von den Größentabellen, die die Shops selbst anbieten, das entsprechende
                Äquivalent zu den Konfektionsgrößen ermittelt.
              </p>
              <p>
                Die Gesamtproduktzahlen von ASOS und boohoo konnten nicht ermittelt werden, da die
                Kategorien Produktüberschneidungen hatten. Auf Nachfrage erhielten wir dazu leider
                keine Auskunft. Die Produktmengen für Plus Size Produkte konnten abgelesen werden,
                weil die Webseiten jeweils eigene Bereiche für Plus Size Mode zur Verfügung stellen.
              </p>
              <h4>Wie viele Produkte Plus Size</h4>
              <p>
                Wenn keine allgemeine Oberkategorie für Bekleidung angeboten wurde oder die Anzahl
                der betroffenen Produkte nicht durch Filter/Zählung ersichtlich war, haben wir für
                die Anzahlen von Plus Size Produkten auch fundierte Schätzungen abgegeben: Am
                Beispiel von drei Hauptkategorien konnten wir den Anteil der Plus Size Produkte
                herausfinden und haben diesen auf die Gesamtproduktzahl hochgerechnet.
              </p>
              <p>
                Bei Shops, die einen eigenen Bereich für Plus Size Produkte anbieten, haben wir die
                Produktanzahlen aus diesem Bereich genommen. Wenn möglich haben wir diese Zahlen mit
                dem Gesamtproduktzahlen verglichen, die wir auf die entsprechenden Größen gefiltert
                haben.
              </p>
              <h4>Produkte ab Größe 46</h4>
              <p>
                Wir haben uns dazu entschieden neben der Anzahl von Plus Size Produkten auch die
                Anzahl der Produkte ab Größe 46 aufzunehmen.
              </p>
              <p>
                Als Plus Size werden im allgemeinen Größen ab der Konfektionsgröße 42 bezeichnet.
                Aus persönlicher Erfahrung und dem Feedback, das wir über die Jahre über Social
                Media, Nutzerumfragen etc. sammeln konnten, wissen wir jedoch, dass viele
                Bekleidungsgeschäfte die Größen 42 und auch 44 durchaus oft anbieten, aber die
                Auswahl meist ab Größe 46+ stark abfällt.
              </p>
              <p>
                Wer online nach Plus Size Kleidung sucht, sucht also meist nach einer
                Konfektionsgröße 46 oder größer. Dies können wir in unserem Shop zum Beispiel auch
                deutlich an der Filternutzung nachvollziehen: Betrachtet man zum Beispiel die
                Klick-Zahlen 2020, sind die Top 5 Größenfilter, die benutzt wurden, die Größen 46 -
                54, wobei die Größe 48 am meisten gefiltert wurde.
              </p>
              <p>
                Da unsere Firmengeschichte sich darin begründet, im Plus Size Bereich eine große
                Auswahl zu bieten, sind wir für dieses Anliegen unserer Zielgruppe sehr
                sensibilisiert: Daher halten wir die Information, wie viele Produkte ab Größe 46
                angeboten werden, für eine Markenliste mit den besten Plus Size Marken für das
                mitunter wichtigste Kriterium.
              </p>
              <h4>Plus Size Model Bilder</h4>
              <p>
                Hier war der Fokus auf Models in Produktbildern: Aus Erfahrung wissen wir, dass
                Userinnen Plus Size Models in Produktbildern sehr begrüßen, da so ersichtlich ist,
                wie die Schnitte fallen und sie so informierter einkaufen können.
              </p>
              <p>
                Wir haben für die Shops die Frage, ob es Plus Size Model Bilder gibt, mit „Ja“
                beantwortet, wenn die Produktbilder zumindest in Plus Size Bereichen oder ab einer
                bestimmten Größe Plus Size Models zeigten. „Nein“ haben wir entsprechend eingetragen
                für Shops, die ausschließlich Models mit kleineren Konfektionsgrößen auf ihren
                Produktbildern zeigen. „Vereinzelt“ wurde eingetragen, wenn auch Plus Size Models
                dabei waren, aber nicht viele, oder wenn Plus Size Models in Werbebannern oder
                Stimmungsbildern gezeigt wurden. „Produktbilder“ wurde eingetragen, wenn die
                Produkte allein ohne Models gezeigt wurden.
              </p>
              <h4>Verfügbare Größen</h4>
              <p>
                Hier haben wir uns an den Filtern orientiert, die von den Shops selbst angeboten
                werden. Die kleinste sowie die größte Größe, die noch Produktergebnisse hatte, haben
                wir entsprechend in die Bewertung aufgenommen.
              </p>
              <h4>Kleidungspreise</h4>
              <p>
                Um eine Vorstellung davon zu haben, in welchem Preissegment sich die einzelnen Shops
                bewegen, haben wir die Preise für das günstigste bzw. teuerste Kleid im Shop
                herausgesucht.
              </p>
              <p>
                Dafür haben wir uns zu den jeweiligen Kleiderkategorien navigiert und das Listing
                einmal mit absteigendem und einmal aufsteigendem Preis sortieren lassen. Diese
                Funktion hat jeder Shop angeboten. Für den niedrigsten Preis haben wir die SALE
                Produkte ausgeschlossen und uns stattdessen am ersten Preis ohne Rabattaktion
                orientiert.
              </p>
              <p>
                Diesen Faktor haben wir zwar mit ins Ranking aufgenommen, er dient allerdings nur
                zur Übersicht und fließt ohne Gewichtung in die Gesamtpunktzahl ein. Wir wollten dem
                User hier lediglich einen Überblick ermöglichen, welche Preise in dem Shop zu
                erwarten sind, um einen Eindruck der Preisklassen zu bekommen, ohne dass wir diese
                bewerten wollen.
              </p>
              <h4>Versandkosten</h4>
              <p>Die Versandkosten waren bei jedem Shop ersichtlich.</p>
              <h4>Deutsche Homepage</h4>
              <p>
                Beim Besuch der einzelnen Webseiten der jeweiligen Brands gab es bestimmte
                Kriterien, anhand derer wir ausschließen konnten, dass eine deutsche Version
                vorhanden ist.
              </p>
              <p>
                Zum einen ist das die fehlende Möglichkeit, auf die deutsche Version wechseln zu
                können, zum anderen die Top-Level-Domain Kennzeichnung, die Auskunft darüber gibt,
                ob wir uns auf einer deutschen Webseite befinden.
              </p>
              <h4>Berechnung des Rankings</h4>
              <p>
                Um die Ergebnisse aller untersuchten Faktoren vergleichen zu können, wurden die
                Ergebnisse auf eine Skala von 0 bis 100 normiert. Die Brand mit der höchsten
                Gesamtpunktzahl bei den jeweiligen Einflussfaktoren erhielt die Punktzahl 100. Die
                Brand mit der niedrigsten Gesamtpunktzahl in den jeweiligen Einflussfaktoren erhielt
                die Punktzahl 0. Die Punktzahl aller anderen Brands wurden relativ zu ihren
                Ergebnissen zwischen 0 und 100 eingestuft. Das Endergebnis war die Summe der Werte
                aller Faktoren. Für die Normierung wurde die folgende Normalisierungsformel
                verwendet:
              </p>
              <img src={Formula} />
              <p>
                Die endgültige Punktzahl haben wir berechnet, indem wir die Summe aller gewichteten
                Faktorwerte auf einer Skala von 0-100 standardisiert und dann mit dem jeweiligen
                Gewichtungsanteil multipliziert haben (z. B. beträgt der Gewichtungsanteil für den
                Faktor "Anzahl Produkte Plus Size" 10 %, was einem Wert von 0,1 entspricht), um eine
                gewichtete Endpunktzahl zu erhalten.
              </p>
              <p>
                Schließlich wurden die Cluster-Scores in absteigender Reihenfolge geordnet, um die
                Marken zu erhalten, die die Bedürfnisse kurviger Frauen wirklich ernst nehmen und
                somit am wahrscheinlichsten zu einem zufriedenstellenden Shopping-Erlebnis führen.
              </p>
              <Link onClick={() => setShowFullTextTwo(!showFullTextTwo)}>
                <span className="inverted">
                  <ArrowSvg />
                </span>{' '}
                weniger lesen
              </Link>
            </div>
          )}
          <span id="ueberWundercurves"></span>
          <div className="content-block about">
            <h3>Über Wundercurves</h3>
            <p>
              Als Marktplatz für die kurvige Frau von heute ermöglicht es Wundercurves, schnell und
              unkompliziert moderne Mode in großen Konfektionsgrößen zu finden. So stellt die
              Plattform die größte Auswahl an Mode in großen Größen - sowohl von namhaften Plus Size
              Spezialisten wie sheego oder Ulla Popken als auch von bekannten und etablierten
              größenübergreifenden Marken wie ABOUT YOU oder Nike.
            </p>
            <p>
              Entdecke alle unsere Marken in unserem{' '}
              <Link to="/shop/bekleidung/">Shop für große Größen</Link> oder klicke Dich in unserer
              Tabelle direkt zu Deiner Lieblingsmarke.
            </p>
          </div>
        </Wrapper>
      </Container>
    </Layout>
  )
}

const Wrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;

  > .content-block {
    max-width: 690px;
    margin: 0 auto;
    > h3 {
      ${ty([28, 0, 34], 'Cormorant Garamond')}
      margin: 0px;
    }
    > h4 {
      ${ty([22, 0, 30], 'Cormorant Garamond')}
      margin-top: 30px;
    }
    > p {
      ${ty([14, 0, 19], 'Open Sans')}
      margin: 14px 0 0 0;
      a {
        display: inline;
        ${ty([18, 0, 22], 'Cormorant Garamond', 'bold')}
        cursor: pointer;
      }
    }
    > ul {
      ${ty([14, 0, 19], 'Open Sans')}
      margin-top: 5px;
    }
    > img {
      margin-top: 15px;
      width: 250px;
    }
    > span,
    a {
      display: block;
      ${ty([18, 0, 22], 'Cormorant Garamond', 'bold')}
      margin: 14px 0 0 0;
      cursor: pointer;
      > .inverted {
        > svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  > .about {
    margin-top: 40px;
  }
  > .Table {
    margin: 36px 0;
  }
`

// const Wrapper = styled.div`
//   display: grid;

//   > .Title { grid-area: Title; }
//   > .SubTitle { grid-area: SubTitle; }
//   > .Img1 { grid-area: Img1; }
//   > .Img2 { grid-area: Img2; }
//   > .Img3 { grid-area: Img3; }
//   > .box-1 { grid-area: Box1; }
//   > .box-2 { grid-area: Box2; }
//   > .box-3 { grid-area: Box3; }
//   > .box-4 { grid-area: Box4; }
//   > .box-5 { grid-area: Box5; }
//   > .box-6 { grid-area: Box6; }

//   > div {
//     height: 200px;
//     background: steelblue;
//   }

//   grid: "Title    Title    Title"
//         "SubTitle SubTitle SubTitle"
//         ".        Box2     Box2" 35px
//         "Box1     Box2     Box2"
//         "Box3     Box2     Box2"
//         "Box3     Box4     Box4"
//         "Box3     Box5     Img2"
//         "Img3     Box5     Img2"
//         "Img3     .        Box6"
//         / 3fr 5fr 2fr;
//   grid-gap: 5px;
// `
