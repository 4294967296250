export const labels = {
  id: '#',
  brand: 'Marke',
  isGerman: 'Deutsche Webseite?',
  numPlusProducts: 'Plus Size Produkte',
  num46PlusProducts: 'Produkte ab 46',
  numProducts: 'Produkte Gesamt',
  minSize: 'Größen',
  maxSize: '-',
  plusSizeModels: 'Plus Size Models?',
  delivery: 'Versand',
  priceMin: 'Wie viel kostet ein Kleid?',
  priceMax: '-',
  score: 'Score',
}
