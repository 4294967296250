/* eslint-disable @kaminrunde/firescout/onclick-needs-handle */
import * as React from 'react'
import styled from 'styled-components'
import useTable from './hooks/useTable'
import ty from 'utils/typographie'
import { ms } from 'modules/browser/const'
import FilterSvg from 'svg/Filter'
import MobileModal from './MobileModal'
import { labels } from './const'
import Link from 'theme/atoms/Link'
import ArrowSvg from 'svg/Arrow'
import WucuLogoWithoutFont from 'svg/Inline/WucuLogoWithoutFont.inline.svg'

export default function Table() {
  const table = useTable()
  const [showAllRows, setShowAllRows] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)

  const tableRows = showAllRows ? table.rows : table.rows.slice(0, 16)

  return (
    <Wrapper className="Table">
      <button className="mobile-filter" onClick={() => setShowModal(true)}>
        Tabelle filtern
      </button>
      <table cellSpacing="0">
        <tr>
          <th className="no-pointer">
            {labels.id}
            <br />
            &nbsp;{' '}
          </th>
          <th className="left no-pointer">
            {labels.brand}
            <br />
            &nbsp;{' '}
          </th>

          <th className="mode-mobile" onClick={() => table.sortBy(table.featuredColumn)}>
            {labels[table.featuredColumn]}
            <br />
            <FilterSvg />
          </th>

          <th className="mode-desktop" onClick={() => table.sortBy('numPlusProducts')}>
            {labels.numPlusProducts}
            <br />
            <FilterSvg />
          </th>
          <th className="mode-desktop" onClick={() => table.sortBy('num46PlusProducts')}>
            {labels.num46PlusProducts}
            <br />
            <FilterSvg />
          </th>
          <th className="mode-desktop" onClick={() => table.sortBy('numProducts')}>
            {labels.numProducts}
            <br />
            <FilterSvg />
          </th>
          <th className="mode-desktop" onClick={() => table.sortBy('plusSizeModels')}>
            {labels.plusSizeModels}
            <br />
            <FilterSvg />
          </th>
          <th className="mode-desktop" onClick={() => table.sortBy('maxSize')}>
            {labels.minSize}
            <br />
            <FilterSvg />
          </th>
          <th className="mode-desktop" onClick={() => table.sortBy('priceMin')}>
            {labels.priceMin}
            <br />
            <FilterSvg />
          </th>
          <th className="mode-desktop" onClick={() => table.sortBy('delivery')}>
            {labels.delivery}
            <br />
            <FilterSvg />
          </th>
          <th className="mode-desktop" onClick={() => table.sortBy('isGerman')}>
            {labels.isGerman}
            <br />
            <FilterSvg />
          </th>
          <th onClick={() => table.sortBy('score')}>
            {labels.score}
            <br />
            <FilterSvg />
          </th>
        </tr>
        {tableRows.map((row, i) => (
          <tr key={row.id}>
            <td>{i + 1}</td>
            <td className="upper left">
              {row.link.trim() !== '' ? (
                <Brand special={row.special === 'Spezialist'}>
                  <Link to={row.link} noFollow={true}>
                    <span className="brand">{row.brand}</span>
                    <WucuLogoWithoutFont />
                  </Link>
                </Brand>
              ) : (
                <Brand special={row.special === 'Spezialist'}>
                  <span className="brand">{row.brand}</span>
                </Brand>
              )}
            </td>
            <td className="mode-mobile">{table.transform(row, table.featuredColumn)}</td>

            <td className="mode-desktop">{table.transform(row, 'numPlusProducts')}</td>
            <td className="mode-desktop">{table.transform(row, 'num46PlusProducts')}</td>
            <td className="mode-desktop">{table.transform(row, 'numProducts')}</td>
            <td className="mode-desktop">{table.transform(row, 'plusSizeModels')}</td>
            <td className="mode-desktop">{table.transform(row, 'minSize')}</td>
            <td className="mode-desktop">{table.transform(row, 'priceMin')}</td>
            <td className="mode-desktop">{table.transform(row, 'delivery')}</td>
            <td className="mode-desktop">{table.transform(row, 'isGerman')}</td>
            <td>{table.transform(row, 'score')}</td>
          </tr>
        ))}
      </table>

      <div className="expand-wrapper">
        <button onClick={() => setShowAllRows(!showAllRows)}>
          {showAllRows ? 'Tabelle einklappen' : 'Tabelle ausklappen'}
        </button>
        <div className="divider" />
      </div>

      <div className="legend">
        <div className="special">
          <div className="color" />
          <div className="label">Plus Size Spezialist</div>
        </div>
        <div className="special">
          <div className="color" />
          <div className="label">Plus Size Vertreter</div>
        </div>
        <div className="normal">
          <div className="logo">
            <span></span>
            <WucuLogoWithoutFont />
          </div>
          <div className="link">Direkt auf Wundercurves entdecken</div>
        </div>
      </div>
      <div className="download">
        <Link to="https://res.cloudinary.com/wucu/image/upload/v1610023069/Brandindex/Wundercurves-Plus-Size-Brandindex-2021.pdf">
          Download Tabelle <ArrowSvg />
        </Link>
      </div>

      {showModal && (
        <MobileModal
          onClose={() => setShowModal(false)}
          featuredColumn={table.featuredColumn}
          onSelect={table.setFeaturedColumn}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  > .mobile-filter {
    padding: 8px;
    width: 100%;
    border: 1px solid #707070;
    background: white;
    ${ty([14, 0, 16], 'Raleway')};
    text-transform: uppercase;
    color: #555555;
    cursor: pointer;
    @media (min-width: ${ms.LAPTOP_L}px) {
      display: none;
    }
  }

  > table {
    width: 100%;
    margin-top: 35px;

    .mode-desktop {
      display: none;
    }
    .upper {
      text-transform: uppercase;
    }
    .left {
      text-align: left;
    }

    @media (min-width: ${ms.LAPTOP_L}px) {
      .mode-desktop {
        display: table-cell;
      }
      .mode-mobile {
        display: none;
      }
    }

    > tr > th {
      ${ty([11, 0, 16], 'Raleway', 'bold')};
      text-transform: uppercase;
      color: #262626;
      padding: 5px;
      border-bottom: 1px solid #707070;
      vertical-align: bottom;
      cursor: pointer;
      &.no-pointer {
        cursor: auto;
      }
    }

    > tr > td {
      ${ty([11, 0, 13], 'Raleway')};
      text-align: center;
      padding: 5px;
      border-bottom: 1px solid #e0e0e0;
    }
  }

  > .expand-wrapper {
    position: relative;
    margin-top: 16px;

    > .divider {
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background: #707070;
      top: 50%;
      z-index: -1;
    }

    > button {
      padding: 8px 32px;
      border: 1px solid #707070;
      background: white;
      ${ty([14, 0, 16], 'Raleway')};
      text-transform: uppercase;
      margin: 0 auto;
      display: block;
      color: #555555;
      cursor: pointer;
    }
  }

  > .download {
    margin-top: 10px;
    a {
      color: #555555;
      ${ty([18, 0, 22], 'Cormorant Garamond', 'bold')}
    }
    a:visited {
      color: #555555;
    }
  }

  > .legend {
    margin-top: 30px;
    @media (min-width: ${ms.LAPTOP_L}px) {
      margin-top: 0;
    }
    > div {
      display: flex;

      &.special {
        margin-bottom: 6px;
        > .color {
          background: #eeeced;
        }
      }

      > .color {
        width: 150px;
        border: 1px solid black;
        height: 13px;
      }
      > .label {
        ${ty([11, 0, 13], 'Raleway')};
        text-transform: uppercase;
        margin-left: 6px;
      }

      > .logo {
        width: 15px;
        height: 13px;
        display: flex;
        justify-content: space-between;
        > svg {
          height: 11px;
          width: auto;
        }

        @media (min-width: ${ms.LAPTOP}px) {
          width: 150px;
        }
      }
      > .link {
        ${ty([11, 0, 13], 'Raleway')};
        text-transform: uppercase;
        margin-left: 6px;
        text-decoration: underline;
      }
    }
  }
`

const Brand = styled.td`
  background: ${(p) => (p.special ? '#EEECED' : 'none')};
  display: inline-block;
  width: 100%;
  padding-left: 3px;

  > a {
    display: flex;
    justify-content: space-between;
    height: 12px;
    > .brand {
      color: #555555;
      text-decoration: underline;
    }

    svg {
      width: 13.84px;
      height: auto;
    }
  }
`
