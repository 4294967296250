import * as React from 'react'
import styled from 'styled-components'
import Link from 'theme/atoms/Link'
import ArrowSvg from 'svg/Arrow'
import ty from 'utils/typographie'
import woman3 from './images/woman3.png'
import woman2 from './images/woman2.png'
import woman1 from './images/woman1.png'
import { ms } from 'modules/browser/const'

export default function Grid() {
  const scrollToId = (id: string) => () => {
    const ref = document.getElementById(id)
    if (!ref) return
    window.scroll({
      top: ref.offsetTop - 100,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Wrapper className="Grid">
      <div className="headline">
        <h1>
          Der
          <br /> Wundercurves
          <br /> Brandindex
        </h1>
        <div className="year">
          <span>2</span>
          <span>0</span>
          <span>2</span>
          <span>1</span>
        </div>
        <div className="sub-title">unser Guide für ein optimales Shoppingerlebnis</div>
        <div className="links">
          <Link to="https://res.cloudinary.com/wucu/image/upload/v1610351482/Brandindex/Pressemitteilung-Plus-Size-Brandindex-01-2021.pdf">
            Download Pressemitteilung <ArrowSvg />
          </Link>
          <span onClick={scrollToId('Methodik')}>
            Zur Methodik <ArrowSvg />
          </span>
          <span onClick={scrollToId('ueberWundercurves')}>
            Über Wundercurves <ArrowSvg />
          </span>
        </div>
      </div>

      <div className="block block-1">
        <div className="title">
          29
          <br /> von 50
        </div>
        <div className="desc">Marken arbeiten mit Bildern von Plus Size Models</div>
      </div>

      <div className="img-1">
        <img src={woman3} />
      </div>

      <div className="block block-2">
        <div className="title">
          Ulla
          <br /> Popken
        </div>
        <div className="desc">Gewinner unseres Plus Size Index 2021</div>
      </div>

      <div className="block block-3">
        <div className="title">
          19
          <br /> von 50
        </div>
        <div className="desc">Marken spezialisieren sich auf Plus Size</div>
      </div>

      <div className="block block-4">
        <div className="title">
          Größen
          <br /> bis 68
        </div>
        <div className="desc">gibt’s bei Ulla Popken und Yoursclothing</div>
      </div>

      <div className="block block-5">
        <div className="title">PRETTYLITTLETHING</div>
        <div className="desc">
          bietet die meisten Produkte in den kleinsten Plus Size Größen 42 & 44
        </div>
      </div>

      <div className="block block-6 ranking" onClick={scrollToId('Tabelle')}>
        {'>>'}
        <br /> HIER GEHT’S ZUM GESAMTEN RANKING
      </div>

      <div className="img-2">
        <img src={woman1} />
      </div>

      <div className="img-3">
        <img src={woman2} />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 1020px;
  @media (min-width: ${ms.LAPTOP}px) {
    height: 900px;
  }
  @media (min-width: ${ms.LAPTOP_L}px) {
    width: 690px;
    margin: 0 auto;
  }

  > .headline {
    position: absolute;
    left: 0;
    top: 0;

    > h1 {
      ${ty([32, 0, 40], 'Raleway', 'bold')}
      text-transform: uppercase;
      margin: 0;
      color: black;
    }

    > .year {
      ${ty([100, 0, 100], 'Raleway', 'bold')}
      margin-top: -20px;
      opacity: 0.6;
      > span:nth-child(1) {
        color: #d5e583;
      }
      > span:nth-child(2) {
        color: #72c8b6;
      }
      > span:nth-child(3) {
        color: #86d4e8;
      }
      > span:nth-child(4) {
        color: #f0a87b;
      }
    }

    > .sub-title {
      ${ty([21, 0, 25], 'Raleway', 'bold')}
      color: black;
    }

    > .links {
      margin-top: 16px;
      > a,
      span {
        display: block;
        ${ty([18, 0, 22], 'Cormorant Garamond', 'bold')}
        margin-top: 6px;
        color: black;
        cursor: pointer;
        > svg {
          margin-left: 10px;
        }
      }
    }

    @media (min-width: ${ms.LAPTOP}px) {
      left: 240px;
      width: 290px;

      > h1 {
        ${ty([42, 0, 49], 'Raleway', 'bold')}
      }
    }
  }

  > .block {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    > .title {
      ${ty([45, 0, 60], 'Raleway', 'bold')}
      text-transform: uppercase;
      @media (min-width: ${ms.LAPTOP}px) {
        margin-bottom: 10px;
      }
    }
    > .desc {
      ${ty([16, 0, 22], 'Open Sans')}
      @media (min-width: ${ms.LAPTOP}px) {
        ${ty([18, 0, 22], 'Open Sans')}
      }
    }

    &.ranking {
      cursor: pointer;
    }
  }

  > .block-1 {
    right: 0;
    top: 360px;
    height: 240px;
    width: 64%;
    background: #86d4e866;
    z-index: 2;
    > .title {
      text-transform: inherit;
    }
    @media (min-width: ${ms.LAPTOP}px) {
      top: 410px;
      left: 190px;
      width: 240px;
      height: 220px;
      padding-left: 20px;
      > .title {
        margin-top: -20px;
      }
    }
  }

  > .img-1 {
    position: absolute;
    left: 0;
    top: 390px;
    width: 160px;
    transform: rotateY(180deg);
    z-index: 1;
    > img {
      width: 100%;
    }
    @media (min-width: ${ms.LAPTOP}px) {
      left: 540px;
      top: 280px;
      transform: rotateY(360deg);
    }
  }

  > .img-3 {
    display: none;
    @media (min-width: ${ms.LAPTOP}px) {
      display: block;
      position: absolute;
      left: 40px;
      top: 20px;
      width: 180px;
      z-index: 1;
      > img {
        width: 100%;
      }
    }
  }

  > .block-2 {
    left: 0;
    top: 560px;
    width: 33%;
    background: #72c8b666;
    height: 180px;

    > .title {
      text-transform: uppercase;
      ${ty([21, 0, 25], 'Raleway', 'bold')}
    }
    @media (min-width: ${ms.LAPTOP}px) {
      left: 0;
      top: 370px;
      width: 180px;
      height: 130px;
      > .title > br {
        content: ' ';
        display: none;
      }
    }
  }

  > .block-3 {
    right: 24%;
    width: 40%;
    top: 610px;
    height: 150px;
    background: #f0a87b66;
    z-index: 3;

    > .title {
      text-transform: inherit;
      ${ty([21, 0, 25], 'Raleway', 'bold')}
    }
    @media (min-width: ${ms.LAPTOP}px) {
      top: 680px;
      left: 310px;
      right: unset;
      width: 260px;
      height: 120px;
      > .title > br {
        content: ' ';
        display: none;
      }
    }
  }

  > .img-2 {
    position: absolute;
    right: 10px;
    top: 610px;
    width: 70px;
    > img {
      width: 100%;
    }
    @media (min-width: ${ms.LAPTOP}px) {
      top: 640px;
      left: 190px;
      right: unset;
      width: 110px;
    }
  }

  > .block-4 {
    left: 0;
    width: 33%;
    top: 750px;
    height: 180px;
    background: #d5e58366;
    z-index: 3;

    > .title {
      ${ty([21, 0, 25], 'Raleway', 'bold')}
    }
    > .desc {
      ${ty([14, 0, 22], 'Open Sans')}
    }
    @media (min-width: ${ms.LAPTOP}px) {
      left: 440px;
      top: 370px;
      width: 180px;
      height: 150px;
    }
  }

  > .block-5 {
    right: 0;
    width: 64%;
    top: 770px;
    height: 120px;
    background: #72c8b666;
    z-index: 3;

    > .title {
      ${ty([17, 0, 25], 'Raleway', 'bold')}
    }

    > .desc {
      ${ty([14, 0, 22], 'Open Sans')}
    }
    @media (min-width: ${ms.LAPTOP}px) {
      right: unset;
      left: 440px;
      width: 250px;
      top: 530px;
      height: 140px;
      > .title {
        ${ty([21, 0, 25], 'Raleway', 'bold')}
      }
    }
  }

  > .block-6 {
    right: 0;
    width: 100px;
    top: 900px;
    height: 90px;
    background: #d5e58366;
    z-index: 3;
    color: #555555;
    ${ty([12, 0, 14], 'Raleway', 'bold')}
    @media (min-width: ${ms.LAPTOP}px) {
      left: 60px;
      top: 580px;
      width: 120px;
      height: 110px;
      ${ty([14, 0, 16], 'Raleway', 'bold')}
    }
  }
`
