import * as React from 'react'

export default function useCssCloseAnimation<Fn extends (...args: any[]) => void>(
  timeout,
  cb: Fn
): [boolean, Fn] {
  const [isClosing, setIsClosing] = React.useState(false)
  const timeoutId = React.useRef<null | NodeJS.Timeout>(null)

  const handleClose = (...args: any[]) => {
    if (isClosing) return
    setIsClosing(true)
    timeoutId.current = setTimeout(() => cb(...args), timeout)
  }

  React.useEffect(() => {
    if (!isClosing || !timeoutId.current) return
    return () => {
      if (timeoutId.current) clearTimeout(timeoutId.current)
    }
  }, [isClosing])

  return [isClosing, handleClose as Fn]
}
