import * as React from 'react'

export default function FilterSvg() {
  return (
    <svg
      id="Component_2_2"
      data-name="Component 2 – 2"
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="9"
      viewBox="0 0 7 9"
    >
      <g id="Polygon_4" data-name="Polygon 4" fill="#707070">
        <path
          d="M 5.898116111755371 3.5 L 1.101883769035339 3.5 L 3.5 0.7592957019805908 L 5.898116111755371 3.5 Z"
          stroke="none"
        />
        <path
          d="M 3.5 1.518591403961182 L 2.2037672996521 3 L 4.796232223510742 3 L 3.5 1.518591403961182 M 3.5 0 L 7 4 L 0 4 L 3.5 0 Z"
          stroke="none"
          fill="#707070"
        />
      </g>
      <g id="Polygon_5" data-name="Polygon 5" transform="translate(7 9) rotate(180)" fill="#707070">
        <path
          d="M 5.898116111755371 3.5 L 1.101883769035339 3.5 L 3.5 0.7592957019805908 L 5.898116111755371 3.5 Z"
          stroke="none"
        />
        <path
          d="M 3.5 1.518591403961182 L 2.2037672996521 3 L 4.796232223510742 3 L 3.5 1.518591403961182 M 3.5 0 L 7 4 L 0 4 L 3.5 0 Z"
          stroke="none"
          fill="#707070"
        />
      </g>
    </svg>
  )
}
