import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import formatPrice from 'utils/formatPrice'
import { labels } from '../const'

const transformers = {
  numProducts: (row: Row) => row.numProducts,
  minSize: (row: Row) => `${row.minSize} - ${row.maxSize}`,
  priceMin: (row: Row) => `€${formatPrice(row.priceMin)} - €${formatPrice(row.priceMax)}`,
  delivery: (row: Row) => `€${formatPrice(row.delivery)}`,
  // eslint-disable-next-line react/display-name
  isGerman: (row: Row) =>
    row.isGerman ? <span>&#10003;</span> : <span style={{ opacity: 0.5 }}>&#10008;</span>,
}

type Row = {
  id: string
  brand: string
  special: 'Spezialist' | 'Vertreter'
  isGerman: boolean
  numPlusProducts: number
  num46PlusProducts: number
  numProducts: number
  minSize: number
  maxSize: number
  plusSizeModels: string
  delivery: number
  priceMin: number
  priceMax: number
  score: number
  rel: string
  link: string
}

type Result = {
  rows: Row[]
  /**
   * first call sorts by name ascending
   * second call sorty by name descending (if prev-name === next-name)
   * `default` resets to the normal sorting
   */
  sortBy: (name: keyof Row | 'default') => void
  featuredColumn: keyof typeof labels
  setFeaturedColumn: (name: keyof typeof labels) => void
  transform: (row: Row, name: keyof typeof labels) => string
}

export default function useTable(): Result {
  const gq: { rows: { nodes: Row[] } } = useStaticQuery(graphql`
    query BrandIndexTable {
      rows: allBrandIndexTableRow {
        nodes {
          score
          brand
          special
          delivery
          plusSizeModels
          id
          isGerman
          num46PlusProducts
          numPlusProducts
          numProducts
          priceMax
          priceMin
          minSize
          maxSize
          rel
          link
        }
      }
    }
  `)
  const [rows, setRows] = React.useState(gq.rows.nodes)
  const [sortKey, setSortKey] = React.useState('default')
  const [featuredColumn, setFeaturedColumn] = React.useState<Result['featuredColumn']>(
    'num46PlusProducts'
  )

  const sortBy: Result['sortBy'] = (name) => {
    if (rows.length === 0) return
    else if (name === sortKey) {
      setRows(rows.slice().reverse())
    } else if (typeof rows[0][name] === 'number') {
      const sorted = rows.sort((a, b) => a[name] - b[name])
      setRows(sorted)
      setSortKey(name)
    } else if (typeof rows[0][name] === 'boolean') {
      const sorted = rows.sort((a, b) => a[name] - b[name]).reverse()
      setRows(sorted)
      setSortKey(name)
    } else {
      const sorted = rows.sort((a, b) => a[name].localeCompare(b[name], 'de'))
      setRows(sorted)
      setSortKey(name)
    }
  }

  const transform: Result['transform'] = (row, name) => {
    if (transformers[name]) return transformers[name](row)
    return row[name]
  }

  return { rows, sortBy, featuredColumn, setFeaturedColumn, transform }
}
